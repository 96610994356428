angular.module('fg').controller('fgEditPaletteCategoriesController', [
  '$scope',
  'fgConfig',
  function ($scope, fgConfig) {
    $scope.categories = fgConfig.fields.categories;
    $scope.selectedCategory = undefined;

    $scope.setCategory = function (name) {
      // webpack build name has spaces in both ends so need to trim to get categories filtered correctly
      const trimmedName = name.trim();

      $scope.categoryName = trimmedName;
      $scope.category = $scope.categories[trimmedName];
    };

    if (!$scope.category) {
      //noinspection LoopStatementThatDoesntLoopJS
      for (var name in $scope.categories) {
        //noinspection JSUnfilteredForInLoop
        $scope.setCategory(name);
        break;
      }
      //make all fields default
      $scope.setCategory('All field types', null);
    }
  }
]);
